@import "scss/constants";

$side-padding: 30px;
$primary: $primary--live;
$black: #000000;
$white: #fff;

$gradient: linear-gradient(-124deg, #e15ada 0%, #6f43ff 50%, #00f6d5 100%);

$dark: #1a1d24;
$border-radius: 28px;
$large-shadow: box-shadow--large(0.34);
$login-max-width: 540px;
$modal-max-width: 540px;
$page-max-width: 1240px;

body {
  &.no-scroll {
    overflow: hidden;
  }
}

.loading-screen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: $primary;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity 1000ms $transition-function;
  opacity: 1;
  &.hide {
    opacity: 0;
  }

  .burningman-loading-container {
    position: relative;
  }
  .loading-sparkle-1,
  .loading-sparkle-2 {
    position: absolute;
    display: block;
    z-index: z(loading-page-sparkle);
    height: 23px;
    width: 15px;
    opacity: 1;
    left: 20%;
    transform: scale(1);
    background-image: url(./img/loading-sparkle-icon.png);
    background-size: 15px 23px;
    animation: pulse 2s cubic-bezier(0.77, 0, 0.175, 1) infinite;
    opacity: 0;
    &.loading-sparkle-2 {
      right: 16%;
      left: auto;
      top: 20%;
      animation-delay: 0.2s;
    }
  }

  .burningman-loading {
    position: relative;
    height: 152px;
    width: 76px;
    margin: 0 auto 30px;

    -moz-mask-box-image: url(./img/burningman-logo-2.png);

    -webkit-mask-box-image: url(./img/burningman-logo-2.png);
    mask-box-image: url(./img/burningman-logo-2.png);
    background-color: rgba($white, 0.2);
    animation: sparkleanim 4s cubic-bezier(0.77, 0, 0.175, 1) infinite;

    .burningman-loading-anim {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: $white;
      opacity: 1;
      height: 0;
      animation: loadingburningman 2s cubic-bezier(0.77, 0, 0.175, 1) infinite;
    }
  }

  .loading-randomquote {
    display: block;
    opacity: 0;
    transform: translateY(40px);
    transition: all 800ms $transition-function;
    &.show {
      opacity: 1;
      transform: translateY(0px);
    }
  }
}

@keyframes sparkleanim {
  0% {
    transform: scale(0.2);
  }

  25% {
    transform: scale(1) rotate(180deg);
  }
  50% {
    transform: scale(0.2);
  }

  75% {
    transform: scale(1) rotate(-180deg);
  }
  100% {
    transform: scale(0.2);
  }
}

@keyframes loadingburningman {
  0% {
    opacity: 0;
    height: 0;
  }
  4% {
    opacity: 1;
  }
  20% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    height: 100%;
  }
}
