@import "scss/constants";

$sand: #937c63;

$top-margin: 20px;
$vertical-padding: 20px;
$side-padding: 30px;
$border-radius: 28px;
$large-shadow: box-shadow--large(0.34);
$login-max-width: 540px;
$login-wide-max-width: 1140px;

$black: #000000;
$white: #fff;

$gradient: linear-gradient(-124deg, #e15ada 0%, #6f43ff 50%, #00f6d5 100%);

$dark: #1a1d24;
$modal-max-width: 540px;
$page-max-width: 1240px;

.checkbox {
  display: block;
  text-align: left;
  margin-bottom: 1rem;
  cursor: pointer;
  padding: 6px 0 6px 40px;
  &:before {
    content: "";
    position: absolute;
    margin-left: -40px;
    margin-top: -6px;
    height: 30px;
    width: 30px;
    border-radius: 15px;
    background-color: rgba($white, 0.12);
    box-shadow: 0 0 0 2px rgba($white, 0.4);
  }
  &:hover {
    &:before {
      box-shadow: 0 0 0 2px rgba($white, 1);
    }
  }
  &.checkbox-checked {
    &:before {
      background-color: $primaryColor;
      box-shadow: 0 0 0 2px rgba($white, 0);
      background-image: url(/assets/icons/checkbox-checked.png);
      background-size: 30px 30px;
    }
  }
}

.page-container {
  background-color: $dark;
  display: flex;
  flex-direction: column;
  flex: 1;
  padding-bottom: $spacing--xxl;

  .login-container {
    align-self: center;

    .header-message {
      text-align: center;
      margin-bottom: $spacing--xxl;
    }

    .subheader-message {
      text-align: center;
    }
  }
}

.questions-container {
  padding-top: $spacing--xxl;
}

.page-container-onboarding {
  height: 100%;
  position: absolute;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .login-welcome-title {
    font-style: italic;
    font-weight: 900;
    text-align: center;
    margin: 20px 40px 0;
  }

  .login-welcome-subtitle {
    text-align: center;
    padding-bottom: 30px;
  }
}

.hero-logo {
  &.coreality {
    margin: 0 auto 50px;
    background-image: url(/assets/images/coreality-logo-sparkles.png);
    width: 270px;
    height: 106px;
    background-size: 270px 106px;
  }

  &.sparkle {
    margin: 0 auto;
    background-image: url(/assets/images/Sparkle_logo_white_yellow.png);
    background-repeat: no-repeat;
    background-position: center;
    width: 280px;
    height: 225px;
    background-size: 280px;
  }

  &.github {
    margin: 0 auto;
    background-image: url(/assets/images/Summit_Logo.png);
    background-repeat: no-repeat;
    background-position: center;
    width: 580px;
    height: 225px;
    background-size: 280px;
  }

  &.github-plain {
    margin: 0 auto;
    background-image: url(/assets/images/rko-logo.png);
    background-repeat: no-repeat;
    background-position: center;
    width: 580px;
    height: 225px;
    background-size: 400px;
  }
}

.auth-container {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  flex: 1;
  background-color: #1a1d24;
  height: 100vh;

  .logo-container {
    width: $login-max-width / 2;
    padding-bottom: 50px;
  }

  .auth-form-container {
    .form-container {
      display: inline-table;
      flex: 0;
      width: $login-max-width;
      margin: 0 auto;
      padding: $side-padding;
      background-color: $black;
      box-shadow: $large-shadow;
      text-align: center;
      border-radius: $border-radius;

      .form {
        margin-top: 2rem;
      }
    }

    .register-form-title {
      font-weight: 900;
      font-style: italic;
      font-size: 2em;
      color: $white;
    }
  }
}

.secondary-action {
  margin-top: 2rem;
  font-size: 1.2em;
  opacity: 0.8;
  width: 100%;
  margin: 10px auto;
  text-align: center;
}

.profile-picture-preview {
  width: 80px;
  height: 80px;
  border: 3px solid $white;
  margin-bottom: -10px;
}

.profile-picture-preview-container {
  display: flex;
  justify-content: center;
  z-index: z(account-profile-picture-preview);
  background-color: transparent;
  border: none;
}

.profile-picture-input {
  display: none;
}

.profile-picture-button {
  max-width: 300px;
  display: flex;
  align-items: center;
  border-radius: 22px;
  border: solid $primary;
  padding: 15px 50px;
  color: $white;
  font-weight: 700;
  margin-bottom: $spacing--lg;
  margin-top: $spacing--lg;
  background-color: $black;

  &:hover {
    transform: translateY(-1px);
    background-image: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.6) 0%,
      rgba(0, 0, 0, 0.6) 100%
    );
    background-color: darken($primary, 20%);
  }
}

.Profile {
  height: 100%;
  position: absolute;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;
  padding-top: 40px;

  .login-welcome-title {
    margin-top: $spacing--xxl;
    font-style: italic;
    font-weight: 900;
    text-align: center;
    width: 50%;
    text-align: center;
  }

  .login-welcome-subtitle {
    text-align: center;
    margin-bottom: $spacing--lg;
    margin-top: $spacing--md;
  }

  &__summit-snap {
    margin: auto;
    text-decoration: none;
    text-align: center;
    display: block;

    &:hover {
      color: white;
      text-decoration: none;
    }
  }

  &__issues-text {
    margin-top: $spacing--xxl;
    color: $secondary--light;
    font-style: italic;
  }
}

.profile-picture-upload-form {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 10px;
}

@media (max-width: $login-max-width) {
  .login-container {
    border-radius: 0;
  }
}

.question-input {
  text-align: center;
}

.splash-page-container {
  width: 100%;
  height: 100%;
  overflow: hidden;
  text-align: center;
  .playa-img {
    opacity: 0.5;
    width: 100%;
  }
  .step-container {
    max-height: 100%;
    position: absolute;
    top: 10%;
    left: 20%;
    right: 20%;
  }
  .step0-container {
    top: 50%;
    max-width: $login-max-width;
    margin: 0 auto;
    padding: $side-padding;
    background-color: $dark;
    box-shadow: $large-shadow;
    text-align: center;
    border-radius: $border-radius;
    .enter-button {
      margin-top: 15px;
    }
  }
  .welcome-to-the-burn {
    position: absolute;
    top: 25%;
    left: 20%;
    right: 20%;
    height: 100px;
    line-height: 100px;
    font-size: 45px;
    //background-color: $sand;
    //border: solid 1px black;
    font-weight: bolder;
    color: black;
    text-shadow: 1px 1px $white;
  }
  .enter-the-burn {
    position: absolute;
    top: 40%;
    left: 30%;
    right: 30%;
    height: 200px;
    padding: 20px;
    font-size: 18px;
    background-color: darken($sand, 20%);
    border: solid 1px black;
    .enter-button {
      margin-top: 30px;
    }
  }
  .ten-principles-burning {
    max-width: $login-max-width;
    margin: 0 auto;
    padding: $side-padding;
    background-color: $black;
    box-shadow: $large-shadow;
    text-align: center;
    border-radius: $border-radius;
    overflow-y: auto;
    bottom: 10%;

    .principle-name {
      font-weight: bold;
      font-size: 15px;
      cursor: pointer;
    }
    .principle-description {
      font-size: 12px;
      cursor: pointer;
    }
    .form {
      margin-top: 2rem;
      //width: 40%;
      padding: 1rem;
      display: inline-block;
      img {
        cursor: pointer;
      }
      .video-name {
        // display: inline-block;
        // text-align: left;
        font-weight: bold;
        font-size: 15px;
        cursor: pointer;
      }
      .video-description {
        // display: inline-block;
        //text-align: left;
        font-size: 12px;
        cursor: pointer;
      }
      .video-thumb {
        display: inline-block;
        cursor: pointer;
      }
    }
    button {
      margin-top: 2rem;
    }
  }
  .step4-container {
    top: 20%;
    bottom: 20%;
    max-width: $login-max-width;
    margin: 0 auto;
    padding: $side-padding;
    background-color: $black;
    box-shadow: $large-shadow;
    text-align: center;
    border-radius: $border-radius;
    .step-4-buttons {
      width: 100%;
      padding: 15px;
      border-radius: $border-radius;
      background-color: $dark;
      margin-bottom: 20px;
      cursor: pointer;
      height: 130px;
      transition: all 400ms $transition-function;
      &:hover {
        box-shadow: 0 0 0 4px rgba($white, 0.62);
        background-color: lighten($dark, 4%);
      }
    }
  }
  .step5-container {
    top: 8%;
    bottom: 8%;
    max-width: $login-max-width;
    margin: 0 auto;
    padding: $side-padding;
    background-color: $black;
    box-shadow: $large-shadow;
    text-align: center;
    border-radius: $border-radius;
    .microphone {
      width: 410px;
      background-color: black;
    }
    .microphone-button {
      width: 200px;
      height: 30px;
      text-align: center;
      line-height: 30px;
      background-color: grey;
      border: none;
      margin-bottom: 15px;
    }
  }
  .step6b-container {
    max-width: $login-wide-max-width;
    margin: 0 auto;
    padding: $side-padding;
    background-color: $dark;
    box-shadow: $large-shadow;
    text-align: center;
    border-radius: $border-radius;
    .step6b-welcome-message {
      margin-bottom: 30px;
    }
  }

  .navigation-guide {
    text-align: left;
    .heading {
      .navigating-header {
        font-size: 4rem;
        font-weight: 700;
        text-transform: uppercase;
        color: $sand;
      }
      .logo-container {
        text-align: right;
        vertical-align: middle;
        height: 100%;

        .logo {
          margin-top: 1rem;
        }
      }
    }
    .content {
      .action-title {
        font-size: 2.4rem;
        font-weight: 700;
        text-transform: uppercase;
        color: rgb(228, 204, 99);
      }
      .action-details {
        font-size: 0.8rem;
        font-weight: 700;
      }
    }
    .cta {
      margin-top: 2rem;
    }
  }
}

.modal-content {
  width: 100%;
  max-width: $modal-max-width;
  margin: 0 auto;
  padding: 20px 0 0 0;
  background-color: $black;
  box-shadow: $large-shadow;
  text-align: left;
  border-radius: $border-radius;
  overflow: hidden;

  h3 {
    margin-bottom: 1rem;
    padding: 0 $side-padding;
  }
  .modal-tabs {
    display: flex;
    flex-wrap: nowrap;
    width: 100%;
    align-items: center; /* Align Items Vertically */
    justify-content: space-around;
    margin-bottom: 2rem;
    border-bottom: 1px solid rgba($white, 0.2);
    overflow-x: auto;
    overflow-y: hidden;
    button {
      background-color: transparent;
      border: none;
      display: block;
      position: relative;
      color: $white;
      text-align: center;
      padding: 4px 10px;
      opacity: 0.7;
      font-weight: 400;
      &:hover {
        opacity: 1;
      }
      &.selected {
        opacity: 1;
        font-weight: bold;
        color: yellow;
        outline: none;
        &:after {
          content: "";
          position: absolute;
          height: 2px;
          border-radius: 4px;
          width: 100%;
          background-color: $white;
          left: 0;
          bottom: -1px;
        }
      }
    }
  }
}
