:root {
  --primary-color: #f6e652;
}

@import "node_modules/bootswatch/dist/darkly/variables";
@import "node_modules/bootstrap/scss/bootstrap";
@import "node_modules/bootswatch/dist/darkly/bootswatch";
@import "./constants.scss";
@import "./customOverrides";

html,
body,
#root {
  height: 100%;
  width: 100%;
}

body {
  @include scrollbar;
  font: 1rem/1.3 "Rubik", "HelveticaNeue", "Helvetica Neue", Helvetica,
    sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  background-color: $black;
  color: $white;
  display: flex;
  justify-content: center;
  font-family: "Rubik", sans-serif;
  overflow: auto;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

// Titles
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 700;
  margin-bottom: 0.5rem;
  line-height: 1.2;
  &.primary {
    color: $primary;
  }
  &.italic {
    font-weight: 900;
    font-style: italic;
  }
}
h1 {
  font-size: 3em;
}
h2 {
  font-size: 2em;
}
h3 {
  font-size: 1.6em;
}
h4 {
  font-size: 1.4em;
}
h5 {
  font-size: 1.2em;
}
h6 {
  font-size: 1em;
}

// Buttons
button.btn,
a.btn,
input.btn {
  display: inline-block;
  padding: 12px 16px;
  font-weight: 700;
  text-decoration: none;
  border-radius: 22px;
  text-align: center;
  font-size: 1em;

  background-color: rgba($primary, 0.24);
  transition: all 400ms $transition-function;
  color: $white;
  transform: translateY(0);

  &:hover {
    transform: translateY(-1px);
    background-color: rgba($primary, 0.18);
  }
  &.btn-primary {
    color: $white;
    background-image: $gradient;
    text-shadow: 0 2px 4px rgba(0, 0, 0, 0.21);
    &:hover {
      background-color: darken($primary, 14%);
    }
  }
  &.btn-secondary {
    color: $white;
    background-color: rgba(124, 70, 251, 0.4);
    text-shadow: 0 2px 4px rgba(0, 0, 0, 0.21);
    &:hover {
      background-color: rgba(124, 70, 251, 0.8);
    }
  }
  &.btn-danger {
    color: $white;
    background: $danger-gradient;
    border-color: rgba(0, 0, 0, 0);
    text-shadow: 0 2px 4px rgba(0, 0, 0, 0.21);
    &:hover {
      background-color: darken($red, 14%);
    }
  }

  &.btn-small {
    padding: 6px 12px;
    font-size: 0.9em;
  }
  &.btn-block {
    display: block;
    max-width: 275px;
    margin-bottom: 0.8rem;
  }
  &.btn-centered {
    margin-left: auto;
    margin-right: auto;
  }
  &.disabled,
  &[type="submit"]:disabled {
    color: rgba($white, 0.5);
    background-color: rgba($white, 0.15);
    &.btn-primary {
      color: rgba($white, 0.5);
      background-image: none;
      background-color: rgba($white, 0.15);
    }
  }
}

.input-error {
  color: red;
}

button.btn {
  font-weight: 700;
  text-decoration: none;
  color: rgba(0, 0, 0, 0.7);
  display: inline-block;
  font-weight: 700;
  text-decoration: none;
  border-radius: 22px;
  text-align: center;
  font-size: 1em;

  background-color: $primaryColor;
  transition: all 400ms $transition-function;
  color: $black;
  transform: translateY(0);

  &:hover:enabled {
    transform: translateY(-1px);
    background-image: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.4) 0%,
      rgba(0, 0, 0, 0.4) 100%
    );
  }

  &.btn-block {
    display: block;
    max-width: 275px;
    margin-bottom: 0.8rem;
  }

  &.btn-centered {
    margin-left: auto;
    margin-right: auto;
  }

  &.btn-primary {
    // Background image has to be set to 'none' because by default the Button has gradient image
    background-image: none;
    background-color: $primary;

    &:hover {
      background-image: none;
      background-color: $primary--darker;
    }
  }

  &.btn-secondary {
    // Background image has to be set to 'none' because by default the Button has gradient image
    background-color: $secondary--light;

    &:hover {
      background-image: none;
      background-color: $secondary;
    }
  }

  &.disabled {
    color: rgba($white, 0.2);
    background-image: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.2) 0%,
      rgba(0, 0, 0, 0.2) 100%
    );
  }
}

// Inputs
input,
textarea {
  // @debt remove style auto-setting for inputs
  -webkit-appearance: none;
  border: none;
  outline: none;
  background-color: rgba($white, 0.12);
  width: 100%;
  position: relative;
  cursor: pointer;
  font-size: 1em;
  padding: 12px;
  border-radius: 22px;
  text-align: center;
  color: $white;

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:active,
  &:-webkit-autofill:focus {
    background-color: rgba(white, 0) !important;
  }
  &:hover {
    background-color: rgba($white, 0.16);
  }
  &:focus {
    box-shadow: 0 0 0 2px rgba($white, 1);
  }
  &[type="checkbox"]:focus {
    box-shadow: unset;
  }
  &.input-block {
    display: block;
    max-width: 275px;
    margin-bottom: 0.8rem;
  }
  &.wide-input-block {
    display: block;
    margin-bottom: 0.8rem;
  }
  &.align-left {
    text-align: left;
  }
  &.input-centered {
    margin-left: auto;
    margin-right: auto;
  }
  &.btn {
    font-weight: 700;
  }
  &.disabled {
    background-color: rgba($concrete, 1);
    cursor: default;
  }
}

textarea {
  resize: none;
  font-family: inherit;
  font-size: inherit;
  height: 100px;
}

.input-group {
  display: flex;
  flex-direction: column;
  margin-bottom: 0.8rem;

  input.input-block {
    margin-bottom: 0.3rem;
  }
  .input-info {
    font-size: 0.8em;
    opacity: 0.8;
    align-self: center;
  }
  &.input-info {
    align-self: center;
  }
  .input-error {
    text-align: center;
    color: #e30b0b;
    font-size: 0.9em;
    font-weight: bold;
  }
}

.announcement-list,
.chat-list {
  height: 200px;
  overflow: auto;
}

.profile-icon {
  border-radius: 50%;
  margin: 1px;
  cursor: pointer;
  object-fit: contain;
}

.room-attendance {
  cursor: pointer;
  border-radius: 10px;
  padding: $spacer * 0.25 $spacer * 0.75;
  background: $black;
  color: $white;

  &.positioned {
    position: absolute;
    opacity: 0.5;
    background: $white;
    color: $black;
  }

  .icon {
    border-radius: 10px 0 0 10px;
    padding: $spacer * 0.25 $spacer * 0.75;
    background: theme-color("primary");
  }

  .attendance-number {
    margin-right: 10px;
  }
}

a,
.button--a {
  color: $white;
  text-decoration: underline;

  &:hover {
    color: $primaryColor;
  }
}

.button--a {
  background: transparent;
  border: none;
}

h4 {
  font-weight: bold;
  font-size: 17px;
}

.title {
  font-style: italic;
  font-weight: 700;
}

.collective-icon {
  border-radius: 50%;
  margin-right: 10px;
}

.sparkle-navbar-icon {
  width: 300px;
}

.primary {
  color: $primaryColor;
}

.modal-backdrop {
  background: $dark;
  &.show {
    opacity: 0.8;
  }
  &.chat-backdrop.show {
    opacity: 0;
  }
}

.chatbox-container {
  max-height: 2500px;
}
.modal-content {
  border: none;
  border-radius: 22px;
  background: $black;
  box-shadow: box-shadow--large(0.34);
}

.centered-flex {
  display: flex;
  justify-content: center;
  align-items: center;
}

.between-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.full-page-container {
  display: flex;
  padding: 5px 30px;
  height: calc(100vh - 50px);
  width: 100%;
  & > * + * {
    margin-left: 10px;
  }

  .reduced {
    width: 560px;
    height: 315px;
  }

  .table-container {
    background: $dark;
    border-radius: 0 0 22px 22px;
    transition: all 0.1s linear;
    padding: 0;
    max-height: calc(100vh - 118px);
  }
  .content-column {
    height: 100%;
  }

  .content-column > .row {
    height: 100%;
  }

  .row.reduced {
    height: 100%;
    width: 100%;
  }

  // @debt: this doesn't seem to exist anywhere else yet.. but can we move it into a more specific component-related SCSS file instead of here?
  //   also referenced in src/pages/Account/Venue/VenueMapEdition.scss
  .right-column {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    width: 600px;
    height: 100%;
  }

  .user-list {
    margin-bottom: 20px;
  }

  // @debt: is this class even used anywhere?
  .right-column-content {
    overflow-y: scroll;

    .table-container {
      margin-bottom: 20px;
    }
  }

  .youtube-video {
    transition: all 0.1s linear;
    background-color: $dark;
    color: $white;
  }
}

.full-page-container,
.friendship-container,
.conversation-space-container {
  .content {
    .wrapper {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-template-rows: 25% 25% 25% 25%;
      height: 100%;
      grid-gap: 10px;
      margin: 0 25px;
      width: 100%;
    }
  }
  .participant {
    display: flex;
    height: 100%;
    padding: 0;
    border-radius: 22px;

    .mirrored {
      transform: scale(-1, 1);
    }

    .profile-icon {
      position: absolute;
      bottom: 0;
      left: 0;
      border-radius: 188%;
      z-index: z(global-profile-icon);
    }

    .mute-container {
      position: absolute;
      bottom: 10px;
      left: 0;
      right: 0;
      text-align: center;
      display: flex;
      justify-content: center;
    }

    .mute-other-container {
      position: absolute;
      top: 10px;
      right: 10px;
      text-align: center;
      display: flex;
      justify-content: center;
    }
  }

  video {
    width: 100%;
    max-width: 600px;
    display: block;
    margin: 0 auto;
  }
}

.table-container {
  // min-height: 300px;
  .wrapper {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 10px;

    .participant {
      display: flex;
      flex-grow: 1;
      background: $black;

      .mirrored {
        transform: scale(-1, 1);
      }
    }
  }
}

.scrollable-area {
  .at-table {
    padding: 10px;
    display: flex;
    flex-direction: column;
  }
}

.participant-container {
  .participant {
    display: flex;
    flex-grow: 1;

    .mirrored {
      transform: scale(-1, 1);
    }
  }
}

.chat-modal {
  width: 400px;
  position: absolute;
  right: 10px;
  top: 130px;
  height: calc(100vh - 220px);
  .modal-body {
    background: $dark;
    border-radius: 22px;
  }
  .chatbox-container {
    border: 2px solid $black;
  }
}

input.btn.btn-primary.btn-success {
  background: green;
}

.popover-body {
  padding: 0;
  overflow: hidden;
}

.popover {
  background: $dark;
  border-radius: 22px;
  overflow-y: auto;
  height: calc(100vh - 120px);
}

.bs-popover-bottom-end {
  width: 300px;
  max-width: 99%;
  background: $black;
  border: 1px solid $light-grey;
  background-image: linear-gradient(
    0deg,
    rgba($white, 0.05) 0%,
    rgba($white, 0.05) 100%
  );

  .chatbox-container {
    background: $black;
    background-image: linear-gradient(
      0deg,
      rgba($white, 0.05) 0%,
      rgba($white, 0.05) 100%
    );

    .bubble {
      padding: 10px;
      border-radius: 10px;
      color: $white;

      &.global {
        background: $dark;
      }
      &.private {
        background: $dark;
      }
      &.room {
        background: $dark;
      }
    }
  }
  .chat-input {
    background: $dark;
    margin-bottom: 10px;

    &:hover {
      background: rgba($dark, 0.7);
    }
  }
}

.edit-profile-modal-dialog {
  position: absolute;
  right: 15px;
  width: 300px;
  top: 20px;
}

span.link {
  cursor: pointer;
  text-decoration: underline;

  &:hover {
    color: $primaryColor;
  }
}

.reaction-list {
  flex-direction: column;
  padding: 0 15px;

  .message-bubble {
    font-size: 30px;
    background: black;
    margin-bottom: 25px;
    padding: 10px 20px;
    border-radius: 22px;
    width: 100%;
    word-wrap: break-word;
  }
  .message {
    display: flex;
    img {
      margin-right: 20px;
    }
    .timestamp {
      color: $concrete;
    }
    &.chat-message {
      flex-direction: column;
      .sender-info {
        display: flex;
        align-items: center;
        margin-bottom: 3px;
        img {
          margin-right: 10px;
        }
        .timestamp,
        .delete-button {
          margin-left: 10px;
        }
      }
    }
  }

  &.small {
    .message-bubble {
      font-size: 15px;
      margin-bottom: 10px;

      &.emoji {
        font-size: 25px;
        padding: 0px 20px;
      }
    }
  }
}
.image-input.default-container {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  position: relative;
  border-radius: 10px;
  overflow: hidden;
}

.image-input .default-image {
  width: 100%;
}

.image-input .default-input {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0;
  color: transparent;
}
.image-input .default-input::-webkit-file-upload-button {
  visibility: hidden;
}

.image-input .default-input:focus {
  opacity: 1;
  border-radius: 0;
}

.image-input .empty {
  flex: 1;
  padding: 40px 10px;
  background-color: gray;
  height: 100%;
}

.image-input .empty .text {
  text-align: center;
}

::-webkit-calendar-picker-indicator {
  filter: invert(1);
}

.admin-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px 0;
}

.flex-one {
  flex: 1;
}

.host-icon-container {
  background: $black;
  padding: 10px;
  width: 200px;
  height: 200px;
  border-radius: 50%;

  .host-icon {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover;
  }
}
